import React from "react";
// import "antd/dist/antd.css";
// import './index.css';
import { Select, Space } from "antd";
// const options = [];

// for (let i = 10; i < 36; i++) {
//   const value = i.toString(36) + i;
//   options.push({
//     label: `Long Label: ${value}`,
//     value,
//   });
// }

const SelectOptions = ({
  defaultValue,
  handleChange,
  onSearch,
  options,
  placeholder,
}) => {
  // const [value, setValue] = React.useState(["a10", "c12"]);
  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
      // background: "transparent",
      // border: "none!important",
      color:"black"
    },
    value: defaultValue,
    // options,
    // onChange: (newValue) => {
    //   setValue(newValue);
    // },
    // placeholder: 'Select Item...',
    // maxTagCount: 'responsive',
  };
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <Select
        // mode="multiple"
        style={{
          width: "100%",
        }}
        value={defaultValue}
        options={options}
        onChange={handleChange}
        onSearch={onSearch}
        placeholder={placeholder}
        maxTagCount="responsive"
        showSearch
        optionFilterProp="label"
      />
      {/* <Select {...selectProps} disabled /> */}
    </Space>
  );
};
export default SelectOptions;
